import React from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';

export default function Reviews () {
   
    return  (
        <>            
            <Container>
                <Image fluid src="./img/coming-soon.jpg"  className='mt-3 shadow p-3 mb-5 bg-white rounded'/>
            </Container>   
        </>
    );

}